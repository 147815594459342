const formatType = (type: string): string => {

    let color: string;
   
    switch (type) {
      case 'Eonix': 
        color = 'bg-red-500'; 
        break; 
      case 'PSD': 
        color = 'bg-blue-600'; 
        break; 
      case 'Study': 
        color = 'bg-lime-600'; 
        break; 
      case 'fuchsia': 
        color = 'bg-fuchsia-600'; 
        break; 
      case 'BIW': 
        color = 'bg-slate-900'; 
        break; 
      case 'gray': 
        color = 'bg-gray-900'; 
        break; 
      case 'Team leader': 
        color = 'bg-zinc-500'; 
        break; 
      case 'indigo': 
        color = 'bg-indigo-600'; 
        break; 
      case 'emerald': 
        color = 'bg-emerald-600'; 
        break; 
      case 'pink': 
        color = 'bg-pink-500'; 
        break; 
      case 'violet': 
        color = 'bg-violet-500'; 
        break; 
      case 'Illustrator': 
        color = 'bg-orange-500'; 
        break;
      default: 
        color = 'bg-gray-500'; 
        break; 
    }
   
    return `inline-flex text-white mr-2 rounded-full flex flex-row ${color}`;
  };
  
  export default formatType