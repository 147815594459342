import React, { FunctionComponent, useState, useEffect } from 'react';
import Deal from '../models/deal';
import DEALS from '../models/mock-deal';
import formatDate from '../helpers/format-date';
import formatType from '../helpers/format-type';
import { useHistory } from "react-router-dom";
import { RouteComponentProps, BrowserRouter as Router, Switch, Route} from "react-router-dom";

import { HashLink as Link } from 'react-router-hash-link';

type Params = { id: string };
type Props = {
  deal: Deal,
};
  
const DealsDetail: FunctionComponent<RouteComponentProps<Params>> = ({ match }) => {
    
  const [deal, setDeal] = useState<Deal|null>(null);
  const goToDeal = (id: number) => {
  }

  useEffect(() => {
    DEALS.forEach(deal => {
      if (match.params.id === deal.id.toString()) {
        setDeal(deal);
      }
    })
  }, [match.params.id]);
    
  return (
    <section id='SectionDetail' className='container'>
        <div className='pt-4'>
          <p className=' shadow-inner py-1 mb-2 px-4 rounded-full text-lg text-right'>
              <Link className=' btn btn-link rounded-xl px-4' to="/deals/pathLink#Tag">The era of the chronicle</Link>
              <Link className='btn btn-default shadow-lg px-4 text-lg rounded-xl ml-4' to="/">The ulterior of the deals</Link>
          </p>
        </div>
      { deal ? (
        <article className="container">
            <div className="dealAction fixed right-16 bottom-2 flex justify-end items-center px-4 ">
              {/* <Link to="/kog" target='_blank'>Your link text</Link> */}
              <Link className=' btn btn-default rounded-xl px-4' to="/deals/pathLink#SectionDetail">Lift</Link>
            </div>

            <div className='anim-dealTitle text-3xl px-4'>    
              <h2 className="dealH2 flex justify-center items-center w-full text-sky-800"><b>{ deal.name }</b><b>{ deal.name }</b></h2>
            </div>

            <div className="dealImage p-4 mx-auto flex justify-center bg-white rounded-t-3xl shadow-xl">
                <img className='drop-shadow-xl rounded-2xl ' src={deal.picture} alt={deal.name}/>
            </div>
           
            <div id="Tag" className="dealContent bg-white md:container md:mx-auto rounded-b-3xl shadow-xl p-4 mb-12 mx-4 z-0"> 
                  <div className='flex relative h-0 flex-row justify-between'>
                    <span className="steam steam1"  ></span><span className="steam steam2" ></span><span className="steam steam3"></span>
                  </div>      
                  <table>
                    <tbody>
                      <tr> 
                        <td>Description</td> 
                        <td><strong>{deal.description}</strong></td> 
                      </tr>
                      <tr> 
                        <td>Types</td> 
                        <td>
                          {deal.types.map(type => (
                           <label key={type} className={formatType(type)}><span className='dealType text-xs px-2' >{type}</span></label>
                          ))}</td> 
                      </tr> 
                      <tr > 
                        <td>Date de création</td> 
                        <td>{formatDate(deal.created)}</td> 
                      </tr>
                    </tbody>
                  </table>
            </div>
        </article>
      ) : (
        <h4 className="center">Aucun projet d'afficher !</h4>
      )}
    </section>
  );
}
  
export default DealsDetail;