import React, {FunctionComponent, useState, useEffect} from 'react';
import Ben from '../images/Ben/profil.jpg';
import formatDate from "../helpers/format-date";
import formatType from "../helpers/format-type";
import Type from "../helpers/format-type";
import Deal from '../models/deal';
import DEALDB from '../models/mock-deal';
import DealCard from '../components/deal-card';


const Cv: React.FunctionComponent = () => {
    const[ color,setColor] = useState<string>();
    const [deal, setDeals] = useState<Deal[]>([]);
    useEffect(() => {
        setDeals(DEALDB);
    }, []);

 return ( 
        
         <section className='container py-4'>
                <div className='profil'> 
                    <div className='img'></div>
                    <div className='headerCv'>
                        <div className='h1Cv titleC'><small>Curriculum</small><small>Curriculum</small></div>
                        <div className='h2Cv titleV'><b>Vitae</b><b>Vitae</b></div>                      
                    </div>   
                    <div className='descCv text-sky-800'>
                    <span>Benjamin has good creatives mind</span><br/><span>and a mixture of skills in Ux-Ui design</span><br/><span>for mixed mission balancing both worlds.</span>
                    </div>
                </div>
                <article className=''>
                <h3 className='text-3xl h3Cv '>Projects</h3>                                      
                    {deal.map(deal => (
                        <div className='deal mt-4 w-1/4 float-left cardType mb-2 bg-sk rounded-2xl shadow-xl p-2 duration-500 hover:shadow-none'  key={deal.id}> 
                            <label className='labelName text-sky-800  w-100'>{deal.name}</label>
                            
                            <p className='desc mt-8'>{deal.description}<div className='float-right'><b>{formatDate(deal.created)}</b></div></p>
                            {/* <div className=''>
                                {deal.types.map(type => (
                                <div className='inline-flex'>
                                    <span className="pr-2 type-cv text-xs" key={type}><label className={formatType(type)}><b className='px-2'>{type}</b></label> </span>
                                </div>
                                ))} 
                            </div> */}
                        </div>
                    ))}
                </article>
                <article className='computerSkills px-4 pt-4 md:-translate-x-20 md:translate-y-5 xl:overflow-auto scroll-m-1 md:h-1/3 w-full rounded-3xl shadow-2xl'>
                    <h3 className='text-3xl h3Cv'>Computer Skills</h3>                                      
                    <div className='mb-2 rounded-2xl shadow-xl p-2 duration-500 hover:shadow-none hover:rounded-none compterSkills-card'>
                        <label className='labelSkills font-extrabold text-sky-800'>Language</label>
                        <span>React, Html5, css3, Scss, Sass</span>
                    </div>
                    <div className='mb-2 rounded-2xl shadow-xl p-2 duration-500 hover:shadow-none hover:rounded-none compterSkills-card'>
                        <label>Software</label>
                        <span>Adobe Creative,VisualStudio ,Git , Filezila</span>
                    </div>
                    <div className='mb-2 rounded-2xl shadow-xl p-2 duration-500 hover:shadow-none hover:rounded-none compterSkills-card'>
                        <label>Graphic</label>
                        <span>Illustration, Photos, Vidéos, Effects Spéciaux, Animation 2d/3d, 
Modeling, Mise en page, Flyers, Cartes de visite, Affiches,,..</span>
                    </div>
                    <div className='mb-2 rounded-2xl shadow-xl p-2 duration-500 hover:shadow-none hover:rounded-none compterSkills-card'>
                        <label>Techno</label>
                        <span>MVC, NPM, Pattern Lab, Bootraps, Foundation, Ui Semantic, Joomla
Wordpress, Prestashop, ...</span>
                    </div>
                </article>
                <h3 className='text-3xl mt-12 h3Cv'>Expériences</h3>                    
                <article className='bg-white article p-4 md:-translate-x-20 md:translate-y-5 xl:overflow-auto scroll-m-1 md:h-1/4 w-full rounded-3xl shadow-2xl'>
                    
                </article>
        </section>
 )
}

export default Cv;


