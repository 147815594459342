import React, { FunctionComponent, useState, useEffect } from 'react';
import Deal from '../models/deal';
import DEALDB from '../models/mock-deal';
import DealCard from '../components/deal-card';

const DealList: FunctionComponent = () => {
    const [deal, setDeals] = useState<Deal[]>([]);
    

    useEffect(() => {
        setDeals(DEALDB);
    }, []);

    return (
            <section className="md:container">
                <div className='pt-4'>
                    <p className='shadow-inner py-1 mb-2 px-4 rounded-full text-lg text-right'>
                        There are <strong>{deal.length} </strong>cases in the pocket book tale</p>
                    
                </div>
                <div className="dealList columns-1 sm:columns-2 md:columns-3 xl:columns-4">
                    {deal.length >  0 && deal.map(deal => (
                        <DealCard key={deal.id} deal={deal} />
                    ))}
                </div>
            </section>
    );
}

export default DealList;