import React, { useState} from 'react';
import Logo from './images/Ben/ID/logo.png';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import DealList from './pages/deal-list';
import DealsDetail from './pages/deal-detail';
import Cv from './pages/CurriculumVitae';
import { checkbox } from '@material-tailwind/react';

const App: React.FunctionComponent = () => {

    const [slogan] = useState<String>("Ui-Ux Graphic Develloper");

 return (
         <Router>
                  <header className='bg-white shadow-2xl fixed w-full '>
                     <div className='container m-auto flex flex-col sm:flex-col md:flex-row justify-center md:justify-between items-center rounded-b-full w-full'>
                        <Link to="/" ><img className='h-20 md:translate-y-1' alt='Logo' src={Logo}></img></Link>
                        <h1 className='text-4xl text-sky-500 translate-y-1 flex md:pr-5'>{slogan}</h1>               

                        <div className='flex flex-row'>
                           <input className="menu-icon" type="checkbox" id="menu-icon" name="menu-icon"/>
                           <label htmlFor="menu-icon"></label>
                           <nav className="nav skew-x-3"> 		
                              <ul className="pt-5">
                                  <li><Link to="/" target='_parent' className='btn btn-link w-12 h-12 inline-flex justify-center items-center text-white bg-sky-500 rounded-full text-xl' ><span>Home</span></Link></li>                     
                                 <li><Link to="/cv" target='_parent' className='btn btn-link w-12 h-12 inline-flex justify-center items-center text-white bg-sky-500 rounded-full text-xl' ><span>Cv.</span></Link></li>                     
                              </ul>
                           </nav>

                        </div>
                     </div>
                  </header>
                  <main className='flex justify-center'>
                     
                     <Switch>
                              <Route exact path="/" component={DealList}/>
                              <Route exact path="/cv" component={Cv}/>
                              <Route exact path="/deals" component={DealList}/>
                              <Route path="/deals/:id" component={DealsDetail}/>
                     </Switch>
               
                  </main>
                  <footer className='bg-white  shadow-2xl  z-50 flex justify-center w-full text-center'>
                     <div className='container border-t-4 w-full py-1 border-sky-800 flex flex-row justify-center'>
                     Alpha 1.3.1 <b className='text-sky-500'>&nbsp; | &nbsp;</b> Copyright@2022 <b className='text-sky-500'> &nbsp;|&nbsp; </b> Designed by Ben                     
                     </div>
                   
                  </footer>
             
         </Router>

 )
 
}
  
export default App;