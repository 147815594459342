import PorscheIntro from '../images/Study/Illustrator/Porsche/voiture2.gif';
import JulesIntro from '../images/Study/Illustrator/jule-verne-vec.png';
import SpectacleIntro from '../images/Best-Imaging-Web/Spectacle/AFFICHE-1.png';
import Jackeau from '../images/Best-Imaging-Web/Jackeau/Panneau-01.jpg';
import SynetiqueIntro from '../images/Best-Imaging-Web/Synetique/synetiqueb.jpg';
import AtelierIntro from '../images/Best-Imaging-Web/latelier/atelsmall.jpg';
import Super7Intro from '../images/Best-Imaging-Web/Super7/SUPER7small.jpg';
import LyouIntro from '../images/Best-Imaging-Web/Lyou/clientsmall.jpg';
import LudoIntro from '../images/Best-Imaging-Web/Ludotrotter/ludosmall.jpg';
import PyroIntro from '../images/Best-Imaging-Web/Pyromaniac/Pyrosmall.jpg';
import DMCIntro from '../images/Best-Imaging-Web/DMC/DMCsmall.jpg';
import BIWIntro from '../images/Best-Imaging-Web/biw-icone.png';
import EDNIntro from '../images/Best-Imaging-Web/EDN/cartedevisite.jpg';
import A2BIntro from '../images/Best-Imaging-Web/A2B/SUUMERAfiicheOK-01.jpg';
import TAIntro from '../images/Training/TA/Capture.png';
import LETSORGIntro from '../images/Training/LETSORG/Capture.png';
import KOGIntro from '../images/Training/KOG/Capture.png';
import WBIIntro from '../images/Eonix/WBI/Liste de rapport.png';
import CafeosIntro from '../images/Eonix/Cafeos/Cartedevisite.png';
import SWCSIntro from '../images/Eonix/SWCS/Je valide.png';
import BefitIntro from '../images/Eonix/Befit/logo.png';
import EonixIntro from '../images/Eonix/eonix.jpg';
import CarmenIntro from '../images/Eonix/Carmen/HomePage.png';
import DupuisIntro from '../images/Eonix/Belvision/Welcome.png';
import HEIntro from '../images/Eonix/Helium/Capture.png';
import AbrumetIntro from '../images/Eonix/Abrumet/portail.png';
import AEPSIntro from '../images/Eonix/AEPS/Web.png';
import BXLIntro from '../images/Eonix/BXL/Theme.png';
import BamboIntro from '../images/Eonix/Bamboo/Login.png';
import StageIntro from '../images/Eonix/CafeosStage/Login.png';
import CCUIntro from '../images/Eonix/CCU/webapp.png';
import EOSIntro from '../images/Eonix/Challenge/app.png';
import C19Intro from '../images/Eonix/Covid/Formulaire.png';
import DSourceIntro from '../images/Eonix/DiaSource/Login.png';
import GardensBXLIntro from '../images/Eonix/Gardens-Brussels/menu.png';
import INDIntro from '../images/Eonix/IND/Presentation.png';
import JetteIntro from '../images/Eonix/Jette/Login.png';
import MypebsIntro from '../images/Eonix/Mypebs/HomePage.png';
import NPathIntro from '../images/Eonix/Neuropath/Splashscreen.png';
import RenoIntro from '../images/Eonix/Renolab/DossierConception.png';
import SafeIntro from '../images/Eonix/Safetify/login.png';
import SLSIntro from '../images/Eonix/SLS/SLS.png';
import SPFIntro from '../images/Eonix/SPF/spf.png';
import SPGEIntro from '../images/Eonix/SPGE/spge.png';
import StokiIntro from '../images/Eonix/Stoki/logo.png';
import BeosIntro from '../images/Eonix/Strataia/Login.png';
import SYSIntro from '../images/Eonix/Syscade/Login.png';
import TOKEOIntro from '../images/Eonix/Tokeo/Connexion.png';
import WLineIntro from '../images/Eonix/WaintingLine/Logo.png';
import WalibiIntro from '../images/Eonix/Walibi/Challenges.png';





import Deal from './deal';
   
export const DEALDB: Deal[] = [
{
id: 1,
name: "Best Imaging Web",
description: "--",
picture: BIWIntro,
types: [ "BIW", "Manager" ,"Graphic Designer","Developer","SEO","Photographer"  ],
created: new Date('10-14-2014'),
city: "Charleroi"
},  

 {
  id: 2,
  name: "Spectacle de danse moderne",
  description: "32 H",
  picture: SpectacleIntro,
  types: ["BIW", "Production", "Event",  "Graphic Designer"],
  created: new Date('12-02-2014'),
  city: "Charleroi"
 },
 {
id: 3,
name: "Jackeau",
description: "8 H",
picture: Jackeau,
types: [ "BIW", "Graphic Designer",],
created: new Date('10-14-2014'),
city: "Saussine"
},
{
id: 4,
name: "Synetique",
description: "--",
picture: SynetiqueIntro,
types: [ "BIW","Graphic Designer","Developer" , "Joomla", ],
created: new Date('02-29-2016'),
city: "Lille"
},
{
id: 5,
name: "L'atelier",
description: "--",
picture: AtelierIntro,
types: [ "BIW", "Developer", "Joomla" ,"Prestashop", ],
created: new Date('08-14-2014'),
city: "Bruxelles"
},
{
id: 6,
name: "Super7",
description: "--",
picture: Super7Intro,
types: [ "BIW","Graphic Designer","Developer", "Joomla"  ],
created: new Date('03-29-2014'),
city: "Bruxelles"
},
{
id: 7,
name: "Lyou",
description: "--",
picture: LyouIntro,
types: [ "BIW","Graphic Designer","Developer", "Joomla", "Photographer"  ],
created: new Date('04-22-2014'),
city: "Charleroi"
},
{
id: 8,
name: "Ludotrotter",
description: "--",
picture: LudoIntro,
types: [ "BIW","Graphic Designer","Developer", "Joomla"],
created: new Date('02-02-2015'),
city: "Charleroi"
},
{
id: 9,
name: "Pyromaniac",
description: "--",
picture: PyroIntro,
types: [ "BIW","Graphic Designer","Developer","SEO", "Joomla", "Prod" ],
created: new Date('09-26-2014'),
city: "Anderlues"
},
{
id: 10,
name: "DMC",
description: "--",
picture: DMCIntro,
types: [ "BIW","Graphic Designer","Developer", "Joomla", "Photographer"  ],
created: new Date('11-03-2015'),
city: "Charleroi"
},
{
    id: 11,
    name: "Porsche Cayenne",
    description: "200 H",
    picture: PorscheIntro,
    types: ["Illustrator", "Study","IATA" ],
    created: new Date('26-01-2007'),
    city: "Namur"
   },
{
id: 12,
name: "L'étoile des nuits",
description: "--",
picture: EDNIntro,
types: [ "BIW","Graphic Designer","Developer", "Joomla", ],
created: new Date('10-13-2015'),
city: "Charleroi"
},
{
id: 13,
name: "A2B - Summer League",
description: "--",
picture: A2BIntro,
types: [ "BIW","Graphic Designer"],
created: new Date('09-30-2014'),
city: "Jumet"
},
{
id: 14,
name: "Le petit monde de Jules",
description: "--",
picture: JulesIntro,
types: [ "Study","IATA","Graphic Designer","Illustrator"],
created: new Date('03-24-2007'),
city: "Namur"
},
{
id: 15,
name: "Télé Accueil",
description: "--",
picture: TAIntro,
types: [ "Training","Ui-Ux","Team Manager","Website", "Bootstrap","Design Innovation"],
created: new Date('07-05-2017'),
city: "Mons"
},
{
id: 16,
name: "Let'Org",
description: "--",
picture: LETSORGIntro,
types: [ "Training","Ui-Ux","Website","Bootstrap","Design Innovation"],
created: new Date('06-02-2017'),
city: "Mons"
},
{
id: 17,
name: "Kool Of Geek",
description: "--",
picture: KOGIntro,
types: [ "Training","Ui-Ux","Website JS","Design Innovation"],
created: new Date('06-02-2017'),
city: "Mons"
},
{
id: 18,
name: "Wallonie - Bruxelles International",
description: "--",
picture: WBIIntro,
types: [ "Eonix","Ui-Ux","Front Developer","Prod"],
created: new Date('10-03-2019'),
city: "Belgique"
},
{
id: 19,
name: "Cafeos",
description: "--",
picture: CafeosIntro,
types: [ "Eonix","Ui-Ux","Front Developer","Graphic Designer","Prod"],
created: new Date('08-20-2019'),
city: "Mons"
},
{
id: 20,
name: "Wallonie logement Swcs",
description: "--",
picture: SWCSIntro,
types: [ "Eonix", "Aplication Web" ,"Ui-Ux","Front Developer","Motion Designer","Prod"],
created: new Date('10-19-2020'),
city: "Charleroi"
},
{
id: 21,
name: "Befit Challenge",
description: "--",
picture: BefitIntro,
types: [ "Eonix", "Aplication" ,"Ui-Ux","Front Developer","Motion Designer","Graphic Designer","Prod"],
created: new Date('10-15-2019'),
city: "Mons"
},
{
id: 22,
name: "Eonix",
description: "--",
picture: EonixIntro,
types: [ "CDI", "Ux Designer","Front Developer","SEO", "Graphic Designer", "Project Manager","Prod"],
created: new Date('01-31-2022'),
city: "Mons"
},
{
id: 23,
name: "La monnaie",
description: "--",
picture: CarmenIntro,
types: [ "Eonix", "Ux Designer","Ui Designer"],
created: new Date('01-15-2022'),
city: "Bruxelles"
},
{
id: 24,
name: "Belvision",
description: "--",
picture: DupuisIntro,
types: [ "Eonix", "Les coulisses de l'animation","Application" , "Ux Designer","Ui Designer","Prod"],
created: new Date('10-15-2019'),
city: "Bruxelles"
},
{
id: 25,
name: "Helium",
description: "--",
picture: HEIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer","Prod"],
created: new Date('10-15-2019'),
city: "Bruxelles"
},
{
id: 26,
name: "Abrumet",
description: "--",
picture: AbrumetIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer","Prod"],
created: new Date('10-15-2020'),
city: "Bruxelles"
},
{
id: 27,
name: "AEPS",
description: "--",
picture: AEPSIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer","Prod"],
created: new Date('10-15-2019'),
city: "--"
},
{
id: 28,
name: "Easy Brussels",
description: "--",
picture: BXLIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2019'),
city: "Bruxelles"
},
{
id: 29,
name: "Bamboo",
description: "--",
picture: BamboIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2020'),
city: "Bruxelles"
},
{
id: 30,
name: "Cafeos Stage",
description: "--",
picture: StageIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Mons"
},
{
id: 31,
name: "Plan Cancer Lux",
description: "--",
picture: CCUIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Luxembourg"
},
{
id: 31,
name: "EOS Challenge",
description: "--",
picture: EOSIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Mons"
},
{
id: 32,
name: "Dépistage Covid | CHU",
description: "--",
picture: C19Intro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Lièges"
},
{
id: 33,
name: "Dia Source",
description: "--",
picture: DSourceIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Ottignies-Louvain-la-Neuve"
},
{
id: 34,
name: "Garden Brussels",
description: "--",
picture: GardensBXLIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Bruxelles"
},
{
id: 34,
name: "Institut Notre Dame",
description: "--",
picture: INDIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Bruxelles"
},
{
id: 35,
name: "Jette",
description: "--",
picture: JetteIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Jette"
},
{
id: 36,
name: "Mypebs",
description: "--",
picture: MypebsIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Commision Européenes"
},
{
id: 37,
name: "Neuropath",
description: "--",
picture: NPathIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Commision Européenes"
},
{
id: 38,
name: "Renolab",
description: "--",
picture: RenoIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Bruxelles"
},
{
id: 39,
name: "Safetify",
description: "--",
picture: SafeIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "--"
},
{
id: 40,
name: "SLS",
description: "--",
picture: SLSIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "--"
},
{
id: 41,
name: "SPF",
description: "--",
picture: SPFIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Bruxelles"
},
{
id: 42,
name: "SPGE",
description: "--",
picture: SPGEIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Charleroi"
},
{
id: 43,
name: "Stoki",
description: "--",
picture: StokiIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Bruxelle"
},
{
id: 44,
name: "Strataia",
description: "--",
picture: BeosIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Mons"
},
{
id: 45,
name: "Tokeo",
description: "--",
picture: TOKEOIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Mons"
},
{
id: 46,
name: "Wainting Line",
description: "--",
picture: WLineIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Mons"
},
{
id: 47,
name: "Walibi",
description: "--",
picture: WalibiIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Mons"
},
{
id: 47,
name: "Syscade",
description: "--",
picture: SYSIntro,
types: [ "Eonix", "Application" , "Ux Designer", "Graphic Designer", "Ui Designer",],
created: new Date('10-15-2021'),
city: "Mons"
},
];
  
export default DEALDB;