import React, { FunctionComponent, useState } from "react";
import Deal from "../models/deal";
import formatYear from "../helpers/format-year";
import formatType from "../helpers/format-type";
import { useHistory } from "react-router-dom";

type Props = {
    deal: Deal,
    borderColor?: string
};

const DealCard: FunctionComponent<Props> = ({deal}) => {
    const[ color,setColor] = useState<string>();
    const history = useHistory();
    const goToDeal = (id: number) => {
        history.push(`/deals/${id}`);
    }


    return (
        <div onClick={() => goToDeal(deal.id)} className='dealCard bg-white inline-flex flex-col flex-nowrap border-1 border-sky-100 drop-shadow-xl hover:delay-500 rounded-2xl my-2 p-4 aspect-auto duration-1000 hover:skew-y-2 hover:-skew-x-2 hover:shadow-sky-500 hover:shadow-2xl hover:scale-102 hover:drop-shadow-none' key={deal.id}>       
            <span className="dealYear text-white absolute top-0 right-0 bg-sky-500 px-4 shadow-lg font-extrabold text-lg rounded-l-full pb-8"><p>{formatYear(deal.created)}</p></span>
            
            <label className='text-sky-900 text-xl font-extrabold rounded-t-2xl w-full'>
                <span className="dealName">{deal.name}</span>

            </label>   
            <div className='flex col-span-12 justify-center'>
            <img className='rounded-xl col-span-12 mt-5' src={deal.picture} alt={deal.name}/> 
            </div>
            <div className='flex justify-between col-span-12'>
            </div> 
            <div className='col-span-12'>
                {deal.types.map(type => (
                    <label className={formatType(type)} key={type}><span className="dealType text-xs">{type} </span></label>
                ))} 
            </div>
        </div>
    );

}

export default DealCard 